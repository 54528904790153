<template>
  <div class="左側欄">
    <h2 class="區塊標題">分享新文章</h2>
    <div class="說明">
      與我們分享妳所看到的渣男文章，如果文章合適，就會刊登在網站上！目前只接受 Dcard 文章的連結哦。
    </div>
    <div class="表單">
      <div class="表單標題">文章的網址</div>
      <input v-model="url" placeholder="網址" class="輸入框">
      <div class="分享">
        <button class="按鈕" @click="submit">分享</button>
      </div>
      <div v-if="isDone" class="提示">謝謝分享。我會來看看這篇文章適不適合刊登！</div>
      <div v-if="hasError" class="提示">發生問題，請再試一次。如果持續發生，請<a href="https://instagram.com/chelinchan24">與我聯絡</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Share",
  data() {
    return {
      url : '',
      isDone : false,
      hasError : false
    }
  },
  methods: {
    submit() {
      var raw = JSON.stringify({"url": "testing from vue"});
      var requestOptions = {
        method: 'GET',
      };
      fetch("https://scum.chel.in/.netlify/functions/submit?url=" + this.url, requestOptions)
          .then(response => response.text())
          .then(result => this.isDone = true)
          .catch(error => this.hasError = true );
    }
  }
}
</script>

<style scoped>
.表單 {
  width: 100%;
}

.標題 {
  margin-bottom: 32px;
}

.說明 {
  font-size: 18px;
  margin-bottom: 32px;
}

.表單標題 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.輸入框 {
  height: 48px;
  width: 100%;
  max-width: 600px;
  padding-left: 16px;
  border-radius: 8px;
  border: #DFDFDF 2px solid;
  font-size: 15px;
  box-sizing: border-box;
}

.輸入框:focus {
  border: #666666 2px solid;
  outline: none;
}

.分享 {
  margin-top: 16px;
}

.按鈕 {
  height: 48px;
  color: #fff;
  background: #000000;
  font-size: 16px;
  padding: 0px 24px;
  border-radius: 24px;
}

.提示 {
  margin-top: 32px;
  background: #eaeaea;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
}
</style>
