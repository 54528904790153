<template>
  <div class="左側欄">
    <h2 class="區塊標題">最新收錄</h2>
    <div v-if="error">{{error}}</div>
    <div v-if="posts.length">
      <PostList :posts="posts"/>
    </div>
    <div v-else>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
    </div>
  </div>
  <aside class="右側欄">
    <Aside/>
  </aside>

</template>

<script>
import getPosts from "../composables/getPosts"
import PostList from "@/components/PostList";
import Tags from "@/components/Tags";
import Aside from "@/views/Aside";

export default {
  name: "Home",
  components: {Aside, Tags, PostList},
  setup() {
    const { posts, error, load } = getPosts()

    load()

    return { posts, error, Tags}
  }
}
</script>

<style scoped>
</style>
