<template>
  <div class="標籤">
    <div class="側欄標題">標籤</div>
    <div v-if="posts.length">
      <Tags :posts="posts"></Tags>
    </div>
    <div v-else class="讀取佔位">
      <div class="讀取標籤">
        <div class="標題"></div>
      </div>
      <div class="讀取標籤">
        <div class="標題"></div>
      </div>
      <div class="讀取標籤">
        <div class="標題"></div>
      </div>
      <div class="讀取標籤">
        <div class="標題"></div>
      </div>
    </div>
  </div>
  <hr>
  <div class="關於">
    <div>不要成為我們不該成為的人。<br>｢渣男」由 <a href="https://instagram.com/chelinchan24">@chelinchan24</a> 為妳呈現。<br>
      <a href="https://instagram.com/chelinchan24">聯絡我</a>・<a href="/cookie">Cookie 聲明</a></div>
  </div>
</template>

<script>
import Tags from "@/components/Tags"
import getPosts from "@/composables/getPosts"

export default {
  name: "Aside",
  components: { Tags },
  setup() {
    const { posts, error, load } = getPosts()

    load()

    console.log(posts)

    return { posts, error, Tags }
  }
}
</script>

<style scoped>
.關於 a {
  text-decoration: underline;
}

.讀取標籤 {
  background: #f5f5f5;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 22px;
  margin-bottom: 2px;
  justify-content: space-between;
}

.讀取標籤 .標題 {
  height: 18px;
  width: 50%;
  background: #E2E2E2;
  border-radius: 20px;
}

@media screen and (max-width: 1024px) {
  .讀取佔位 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px 4px;
  }

  .讀取標籤  {
    height: 48px;
    padding: 0px 22px;
  }

  .讀取標籤 .標題 {
    height: 16px;
    width: 50%;
    background: #E2E2E2;
    border-radius: 20px;
  }
}
</style>
