<template>
  <div class="左側欄">
    <h2 class="標題">#{{ tag }}</h2>
    <div v-if="error">{{error}}</div>
    <div v-if="posts.length">
      <PostList :posts="loadPosts"/>
    </div>
    <div v-else>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
      <div class="讀取">
        <div>
          <h3 class="標題"></h3>
          <p class="節錄"></p>
          <p class="節錄"></p>
          <p class="節錄"></p>
        </div>
        <div class="資訊">
          <div class="作者"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="右側欄">
    <Aside/>
  </div>
</template>

<script>
import getPosts from "../composables/getPosts"
import PostList from "@/components/PostList";
import Tags from "@/components/Tags";
import { computed } from "vue";
import { useRoute } from 'vue-router'
import Aside from "@/views/Aside";

export default {
  components: {
    Aside,
    PostList, Tags
  },
  setup() {
    const route = useRoute()
    const { posts, error, load } = getPosts()
    const tag = route.params.tag

    load()

    const loadPosts = computed(() => {
      return posts.value.filter((p) => p.tags.includes(tag))
    })

    return {posts, error, loadPosts, tag}
  }
}
</script>

<style scoped>
.標題 {
  margin-bottom: 32px;
  font-family: 'Noto Serif TC', serif;
  font-weight: 600;
  align-items: flex-start;
  justify-content: flex-start;
}
</style>
