import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Tag from "../views/Tag";
import Share from "@/views/Share";
import Cookie from "@/views/Cookie";

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/share',
    name: 'Share',
    component: Share
  },
  {
    path: '/cookie',
    name: 'Cookie',
    component: Cookie
  },
  {
    path: '/tags/:tag',
    name: 'Tag',
    component: Tag
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
