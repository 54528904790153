<template>
  <div class="區塊 桌面">
      <div class="內容 內容_寬 兩欄">
        <div class="logo">
          <a href="/">渣男</a>
        </div>
        <a href="/share" class="分享">
          分享文章
        </a>
      </div>
  </div>
  <div class="區塊 行動">
    <div class="內容 內容_寬 兩欄">
      <div class="logo">
        <a href="/">渣男</a>
      </div>
      <div class="選項">
        <div class="選單 按鈕" @click="mobileMenu = !mobileMenu"></div>
        <a href="/share" class="分享 按鈕"></a>
      </div>
    </div>
    <div class="選單內容" v-if="mobileMenu">
        <Aside/>
    </div>
  </div>
</template>

<script>
import PostList from "@/components/PostList";
import Aside from "@/views/Aside";
export default {
  name: "NavBar",
  components: {Aside, PostList},
  data() {
    return {
      mobileMenu: false,
    }
  }
}
</script>

<style scoped>
.桌面 {
  display: flex;
}

.行動 {
  display: none
}

.區塊.桌面 {
  height: 96px;
  margin: 0;
  box-shadow: 0 1px 0 0 #EDEDED;
}

.內容 {
  display: flex;
  align-items: center;
}

.兩欄 {
  display: flex !important;
  justify-content: space-between;
}

.logo {
  font-size: 32px;
  font-family: 'Noto Serif TC', serif;
  font-weight: 900;
}

.桌面 .內容 .分享 {
  background: #000000;
  height: 48px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 24px;
  border-radius: 48px;
  font-size: 15px;
}

@media screen and (max-width: 1024px) {
  .桌面 {
    display: none;
  }

  .區塊.行動 {
    box-shadow: inset 0 -2px 0 0 #EDEDED;
    height: 72px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000000;
    background: #fff;
  }
  .行動 {
    display: block;
    width: 100%;
  }

  .logo {
    font-size: 28px;
  }

  .選項 {
    display: flex;
  }

  .按鈕 {
    width: 40px !important;
    height: 40px !important;
    border-radius: 100%;
    margin-left: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .按鈕:after {
    content: '';
    width: 24px !important;
    height: 24px !important;
    display: block;
    background-size: cover !important;
  }

  .按鈕.選單 {
    background: #E9E9E9;
  }

  .按鈕.選單:after {
    background: url(~@/assets/res/rfi_3_h_l.svg);
  }

  .按鈕.分享 {
    background: #000000;
  }

  .按鈕.分享:after {
    background: url(~@/assets/res/rfi_plus.svg);
  }

  .選單內容 {
    width: 100%;
    background: #fff;
    position: fixed;
    padding: 24px 16px;
    box-sizing: border-box;
    height: calc(100% - 72px);
    overflow-y: scroll;
  }
}
</style>
