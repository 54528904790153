<template>
    <a :href="post.link" target="blank" @click="visitPost">
      <h3 class="標題">{{post.title}}</h3>
      <p class="節錄">{{post.clip}}</p>
    </a>
    <div class="資訊">
      <div class="作者" :class=" { 女 : post.postby == 'f', 男 : post.postby == 'm' }"></div>
      <span v-for="tag in post.tags" class="標籤">
        <a :href=" '/tags/'+ tag">
          {{ tag }}
        </a>
      </span>
    </div>
</template>

<script>
export default {
  name: "SinglePost",
  props: ['post'],
  methods: {
    visitPost() {
      gtag('event', '造訪文章：' + this.post.title, {
        'event_category': 'postVisit',
        'event_label': 'posts',
      });
    }
  }
}
</script>

<style scoped>
.標題 {
  margin-bottom: 8px;
}

.節錄 {
  white-space: pre-line;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: #666666;
}

.資訊 {
  margin-top: 16px;
  display: flex;
  align-items: center;
}

.標籤 {
  font-size: 13px;
  background: transparent;
  padding: 2px 6px;
  border-radius: 100px;
  font-weight: 500;
}

.標籤:hover {
  background: #eaeaea;
}

.標籤:before {
  content: "#";
}

.作者 {
  width: 20px;
  height: 20px;
  background-size: cover !important;
  margin-right: 8px;
}

.作者.女 {
  background: url(~@/assets/res/ic_female.svg)
}

.作者.男 {
  background: url(~@/assets/res/ic_male.svg)
}
</style>
