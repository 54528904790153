<template>
  <div class="左側欄">
    <h2 class="區塊標題">Cookie 聲明</h2>
    <div class="說明">
      本網站使用 Cookies 來提供最佳體驗。Cookie 是包含純英文字母和數字的檔案，會由本網站傳送並儲存在妳的裝置中。Cookie 可能藉由完全匿名方式記錄妳的使用行為，並回傳給特定伺服器。妳可以在瀏覽器內設定拒絕本網站使用 Cookie，惟可能影響本網站運作。其刪除方式請參閱妳所使用的瀏覽器提供商所發布的說明。
      <br>
      <br>
      <b>Google Analytics</b><br>
      這是由 Google 提供的網站分析服務。會記錄包括但不局限於妳的 IP 位置、停留時間、瀏覽器種類和在本網站上瀏覽的頁面。更多關於 Google Analytics 的 Cookies 使用資訊，請參閱 <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=zh-TW">Google Analytics 說明</a>。
    </div>
  </div>
</template>

<script>
export default {
  name: "Cookie"
}
</script>

<style scoped>
a {
  text-decoration: underline;
}

.說明 {
  max-width: 600px;
}
</style>
