import {ref} from 'vue'

const getPosts = () => {
  const posts = ref([])
  const error = ref(null)

  const load = async () => {
    try {
      let post = await fetch('https://scum.chel.in/.netlify/functions/api')
      if (!post.ok) {
        throw Error('no post available')
      }
      posts.value = await post.json()
    }
    catch (err) {
      error.value = err.message
      console.log(error.value)
    }
  }
  return { posts, error, load }
}

export default getPosts
