import { reactive } from 'vue'

const useTags = (posts) => {
  const tagsBag = reactive([])
  posts.forEach(post => {
    post.tags.forEach(tag => {
      const tagIndex = tagsBag.findIndex(t => {
        return t['key'] === tag
      })
      const existed = tagIndex !== -1

      if (existed) {
        tagsBag[tagIndex]['time'] += 1
      } else {
        tagsBag.push({ key: tag, time: 1 })
      }
    })
  })


  return { tagsBag }
}

export default useTags
