<template>
  <div class="標籤容器" v-if="tags.length">
    <div v-for="tag in tags" :key="tag">
      <a class="一則標籤" :href="'/tags/'+tag['key']" @click="visitTag(tag)">
        <span class="標籤名稱">#{{ tag['key'] }}</span>
        <span class="數量">{{tag['time']}}</span>
      </a>
    </div>
  </div>
</template>

<script>
import useTags from "../composables/useTags";

export default {
  setup(props) {
    const { tagsBag } = useTags(props.posts);
    return { tags: tagsBag };
  },
  methods: {
    visitTag (tag) {
      gtag('event', '造訪標籤：' + tag.key, {
        'event_category': 'tagVisit',
        'event_label': 'tags',
      });
    }
  },
  props: ["posts"]
};
</script>

<style>
.一則標籤 {
  background: #f5f5f5;
  border-radius: 8px;
  height: 60px;
  display: flex;
  align-items: center;
  padding: 0px 22px;
  margin-bottom: 2px;
  justify-content: space-between;
}

.標籤名稱 {
  font-weight: 500;
}

.數量 {
  color: #00000050
}

@media screen and (max-width: 1024px) {
  .一則標籤 {
    height: 48px;
    padding: 0px 16px;
    font-size: 14px;
  }

  .標籤容器 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px 4px;
  }
}
</style>
