<template>
  <nav>
    <NavBar/>
  </nav>
  <main>
    <section class="區塊">
      <div class="內容 內容_寬 兩欄">
        <router-view/>
      </div>
    </section>
  </main>
</template>

<script>
import NavBar from "@/components/NavBar";
import Aside from "@/views/Aside";

export default {
  name: 'App',
  components: {
    Aside,
    NavBar
  }
}
</script>

<style>
@import './assets/style.css';
main {
  padding: 48px 0px;
  display: flex;
}

.兩欄 {
  display: flex !important;
}

.左側欄 {
  flex: 1;
}

.右側欄 {
  width: 320px;
}

hr {
  padding-top: 8px;
  margin-bottom: 16px;
  border: none;
  box-shadow: 0 1px 0 0 #E8E8E8;
}

.關於 {
  font-size: 14px;
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  main {
    padding: 32px 0px;
  }

  .右側欄 {
    display: none;
  }
}
</style>
