<template>
  <div class="文章清單">
    <div v-for="post in posts" class="文章">
      <SinglePost :post="post"/>
    </div>
  </div>
</template>

<script>
import SinglePost from "@/components/SinglePost";
export default {
  name: "PostList",
  components: {SinglePost},
  props: ['posts']
}
</script>

<style scoped>
.文章 {
  margin-bottom: 32px;
  padding-bottom: 32px;
  background: #FFFFFF;
  box-shadow: 0 1px 0 0 #E8E8E8;
}
</style>
